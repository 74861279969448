import { useState } from "react";
import { AutocompletedAddressValues } from "@pepdirect/v3/types";
import { AutocompleteContextInterface } from "v3/types/autocomplete";

export function useAutocompleteProvider(): {
  autocompleteContextValue: AutocompleteContextInterface;
} {
  const [shipSelectedFromAutocomplete, setShipSelectedFromAutocomplete] =
    useState<AutocompletedAddressValues | null>(null);

  const autocompleteContextValue = {
    setShipSelectedFromAutocomplete: (
      props: AutocompletedAddressValues | null
    ) => {
      setShipSelectedFromAutocomplete(props);
    },
    shipSelectedFromAutocomplete,
  };

  return { autocompleteContextValue };
}
