import React, { useEffect, useRef } from "react";
import Router, { useRouter } from "next/router";
import getConfig from "next/config";
import App, {
  AppProps,
  AppInitialProps,
  AppContext as NextAppContext,
} from "next/app";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import NProgress from "nprogress";
import TagManager from "react-gtm-module";
import { setDefaultOptions } from "date-fns";
import { enUS, pl } from "date-fns/locale";
import styled, {
  StyleSheetManager,
  ThemeProvider,
  createGlobalStyle,
} from "styled-components";

// context
import { AutocompleteContext } from "v3/context/autocomplete";
import { BannerContext } from "v3/context/banner";
import { BraintreeContext } from "v3/context/braintree";
import { LocalizationContext } from "v3/context/localization";
import { PortalErrorContext } from "v3/context/portalError";
import { StripeContext } from "v3/context/stripe";
import { TenantContext } from "v3/context/tenant";

// hooks
import { useAutocompleteProvider } from "v3/context/hooks/useAutocompleteProvider";
import { useBannerProvider } from "v3/context/hooks/useBannerProvider";
import { useBraintreeProvider } from "v3/context/hooks/useBraintreeProvider";
import { useLocalizationProvider } from "v3/context/hooks/useLocalizationProvider";
import { usePortalErrorProvider } from "v3/context/hooks/usePortalErrorProvider";
import { useStripeProvider } from "v3/context/hooks/useStripeProvider";
import { useTenantProvider } from "v3/context/hooks/useTenantProvider";

// services
import client, {
  updateBasePathOnClient,
  updateDevBasePathOnClient,
} from "v3/services/graphql";
import createSsrClient from "v3/services/ssrClient";
import {
  BrandConfigTrackingFonts,
  CurrentUserIdDocument,
  CurrentUserIdQuery,
  LocalizationDocument,
  LocalizationQuery,
  LocalizationQueryVariables,
  PaymentProcessor,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "v3/services/graphql/generated";

// helpers
import { getBasePath, isServerSide } from "v3/helpers/api";
import { addGitShaToWindow } from "@pepdirect/v3/helpers/gitSha";
import { shouldForwardProp } from "@pepdirect/v3/helpers/transientProps";

// theme
import { getTheme } from "v3/helpers/theme";
import { ThemeInterface } from "@pepdirect/v3/theme";

// fonts
import { generateFontFaces } from "@pepdirect/v3/helpers/fonts";
import { useFontTracking } from "@pepdirect/v3/hooks/useFontTracking";
import { FontTrackingiFrame } from "@pepdirect/v3/ui/FontTrackingiFrame";

// config
import { environment, isLocalEnv } from "v3/config";

// styles
import "v3/styles/app/index.scss";
import "v3/styles/app/stylingOverrides.scss";
/* Importing and customizing nprogress */
import "v3/styles/app/nprogress.scss";

addGitShaToWindow();

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", () => window?.scrollTo(0, 0));

interface CheckoutPortalAppInitialProps extends AppInitialProps {
  devBasePath?: string;
  localization: LocalizationQuery["localization"] | null;
  tenant: TenantQuery["tenant"];
  theme: ThemeInterface;
}

interface CheckoutPortalAppProps extends AppProps {
  devBasePath?: string;
  localization: LocalizationQuery["localization"] | null;
  tenant: TenantQuery["tenant"];
  theme: ThemeInterface;
}

const GlobalStyle = createGlobalStyle`
  body {
    ${({ theme }) => `
    background-color: ${theme.page?.backgroundColor || ""};
    font-size: ${theme.typography.body?.fontSize || ""};
    color: ${theme.typography.body?.color || ""};
    line-height: ${theme.typography.body?.lineHeight || ""};
    `}
  }
`;

export const StyledPageLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

CheckoutPortal.getInitialProps = async (
  appContext: NextAppContext
): Promise<CheckoutPortalAppInitialProps> => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  let userId: NonNullable<CurrentUserIdQuery["currentUser"]>["id"] | null =
    null;
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  if (isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext.ctx.req);
  } else {
    gqlClient = client;
  }
  let devBasePath;

  try {
    const userData = await gqlClient.query<CurrentUserIdQuery>({
      query: CurrentUserIdDocument,
    });
    userId = userData?.data?.currentUser?.id || null;
  } catch (e) {
    // logged in errorLink
  }

  // When we add cookies to the request for tenant query,
  // if the cookie is not valid, then it returns 401 unauthenticated error.
  // To prevent this, we detach cookies from the request before making request to them
  // if the user cookie is not valid. If cookie is not set, then this would not be a problem.
  if (!userId && isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext.ctx.req, true);
  }

  /* Set base path for server-side axios and GQL clients */
  if (isServerSide(appContext)) {
    devBasePath = getBasePath(appContext.ctx.req);
  }

  const { ctx } = appContext;
  const acceptLanguageHeader = ctx?.req?.headers?.[
    "pepdirect-accept-language"
  ] as string;

  /* eslint-disable no-useless-catch */
  try {
    const [localization, tenant] = await Promise.all([
      gqlClient.query<LocalizationQuery, LocalizationQueryVariables>({
        query: LocalizationDocument,
        ...(acceptLanguageHeader && {
          context: {
            headers: {
              "accept-language": acceptLanguageHeader,
            },
          },
        }),
      }),
      gqlClient.query<TenantQuery, TenantQueryVariables>({
        query: TenantDocument,
        ...(acceptLanguageHeader && {
          context: {
            headers: {
              "accept-language": acceptLanguageHeader,
            },
          },
        }),
      }),
    ]);

    const theme = await getTheme(tenant.data.tenant?.id);

    return {
      ...appProps,
      devBasePath,
      localization: localization.data.localization || null,
      tenant: tenant.data.tenant,
      theme,
    };
  } catch (e) {
    // make sure to re-throw after catch logs in errorLink so we show 500 error
    throw e;
  }
};

export default function CheckoutPortal({
  Component,
  devBasePath,
  localization,
  pageProps,
  tenant,
  theme,
}: CheckoutPortalAppProps): JSX.Element {
  const devBasePathRef = useRef(devBasePath);

  const gtmId = tenant?.analyticsConfig?.gtm?.containerId;
  if (gtmId && typeof window !== "undefined" && !window.dataLayer) {
    TagManager.initialize({ gtmId });
  }
  const { pathname } = useRouter();

  const title = tenant?.brandConfig.title || "Checkout Portal";
  const locale =
    localization?.currentLocale || localization?.defaultLocale || "en";
  const trackingFonts = tenant?.brandConfig.trackingFonts || [];
  const fonts = tenant?.brandConfig?.customizations.fonts || [];
  const trackFontResources = useFontTracking(
    trackingFonts as BrandConfigTrackingFonts[],
    title
  );
  const defaultCurrencyCode =
    tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "USD";

  useEffect(() => {
    if (setDefaultOptions)
      setDefaultOptions({ locale: locale === "pl" ? pl : enUS });
  }, [locale]);

  useEffect(() => {
    // exposes pod hostname to browser window
    if (window) {
      const { publicRuntimeConfig } = getConfig();
      window._pepdirect = {
        ...window._pepdirect,
        hostname: publicRuntimeConfig.hostname,
      };
    }
  }, []);

  // this is used for changing the background-color
  // on the checkout and order confirmation page
  useEffect(() => {
    if (pathname.includes("[appId]")) {
      document.body.classList.add("is-checkout");
    } else {
      document.body.classList.remove("is-checkout");
    }
  }, [pathname]);

  /* Set base path for client-side axios instance */
  if (!(typeof window === "undefined")) {
    // special dev configuration to allow all apps to run concurrently locally
    if (environment === "dev") {
      if (devBasePathRef.current) {
        updateDevBasePathOnClient({
          client,
          basePath: devBasePathRef.current,
          locale,
        });
      }
    } else {
      updateBasePathOnClient({ client, locale });
    }
  }

  const isStripeTenant =
    tenant?.paymentConfig.processor === PaymentProcessor.Stripe;

  const { tenantContextValue } = useTenantProvider(tenant);
  const { localizationContextValue } = useLocalizationProvider(
    localization,
    defaultCurrencyCode
  );
  const { bannerContextValue } = useBannerProvider();
  const { portalErrorContextValue } = usePortalErrorProvider(localization);
  const { autocompleteContextValue } = useAutocompleteProvider();
  const { braintreeContextValue } = useBraintreeProvider(client);
  const { stripeContextValue } = useStripeProvider(client, isStripeTenant);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(fonts),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}

      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <TenantContext.Provider value={tenantContextValue}>
            <LocalizationContext.Provider value={localizationContextValue}>
              <BraintreeContext.Provider value={braintreeContextValue}>
                <StripeContext.Provider value={stripeContextValue}>
                  <BannerContext.Provider value={bannerContextValue}>
                    <PortalErrorContext.Provider
                      value={portalErrorContextValue}
                    >
                      <AutocompleteContext.Provider
                        value={autocompleteContextValue}
                      >
                        <StyleSheetManager
                          shouldForwardProp={shouldForwardProp}
                        >
                          <StyledPageLayout>
                            <Component {...pageProps} />
                          </StyledPageLayout>
                        </StyleSheetManager>
                      </AutocompleteContext.Provider>
                    </PortalErrorContext.Provider>
                  </BannerContext.Provider>
                </StripeContext.Provider>
              </BraintreeContext.Provider>
            </LocalizationContext.Provider>
          </TenantContext.Provider>
          <GlobalStyle />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}
