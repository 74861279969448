import { useMemo } from "react";
import {
  BrandConfigCustomizationsImage,
  PaymentProcessor,
  TenantQuery,
} from "v3/services/graphql/generated";

export interface TenantContextInterface {
  isStateRequired: boolean;
  isStripeTenant: boolean;
  logoSmall: BrandConfigCustomizationsImage | null;
  tenant: TenantQuery["tenant"];
}

export function useTenantProvider(tenant: TenantQuery["tenant"]): {
  tenantContextValue: TenantContextInterface;
} {
  const isStripeTenant = useMemo(
    () => tenant?.paymentConfig.processor === PaymentProcessor.Stripe,
    [tenant]
  );

  const isStateRequired = useMemo(
    () => !!tenant?.contactConfig?.shippingAddressConfig?.isStateRequired,
    [tenant]
  );

  const logoSmall = useMemo(() => {
    return (
      tenant?.brandConfig?.customizations?.images?.find(
        (img: BrandConfigCustomizationsImage) => img.name === "logoSmallUrl"
      ) || null
    );
  }, [tenant]);

  const tenantContextValue: TenantContextInterface = {
    isStateRequired,
    isStripeTenant,
    logoSmall,
    tenant,
  };

  return { tenantContextValue };
}
