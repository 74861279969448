import { createContext } from "react";
import { AutocompletedAddressValues } from "@pepdirect/v3/types";
import { AutocompleteContextInterface } from "v3/types/autocomplete";

export const AutocompleteContext = createContext<AutocompleteContextInterface>({
  setShipSelectedFromAutocomplete: (
    _props: AutocompletedAddressValues | null
  ) => {},
  shipSelectedFromAutocomplete: null,
});
